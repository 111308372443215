enum RELEASE_TYPE {
    LOCAL = 0,
        DEV = 1,
	    STAGE = 2,
	        PROD = 3
		}

		const CURRENT_RELEASE_MODE: RELEASE_TYPE = RELEASE_TYPE.PROD;

		export function getBaseURL(): string {
		    switch (CURRENT_RELEASE_MODE) {
		            case RELEASE_TYPE.LOCAL:
			                return "http://flexcook.stickint.co.kr/";
					        case RELEASE_TYPE.DEV:
						            return "http://dev-flexcook.stickint.co.kr/";
							            case RELEASE_TYPE.STAGE:
								                return "http://stage.flexcuc.com/";
										        case RELEASE_TYPE.PROD:
												return "https://"+window.location.host;
												            default:
													                return "https://www.flexcook.stickint.co.kr/";
															    }
															    }
