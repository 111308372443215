import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import {BaseResponse, PagingResponse} from "./BaseResponse";
import * as FlexcucClassIndex from "../Pages/FlexcucClassIndex"
import {axiosDefault} from "./ApiBaseModule";

export default class FlexcucClassModule {

    public constructor() {}

    public async reqPatchFlexcucClassMember(req: any) {
        let response: BaseResponse = new BaseResponse();
        let requestURL: string = `/api/v1/flexcucclassmember/${req.COOK_CLASS_MEMBER_IDX}`;

        await ApiBaseModule
            .axiosDefault()
            .patch(requestURL, req)
            .then((result) => { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

    public async reqGetFlexcucClassHistory(memberIdx: bigint) {
        let response: BaseResponse = new BaseResponse();
        let requestURL: string = `/api/v1/flexcucclass/${memberIdx}/history`;

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL)
            .then((result) => { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

    public async reqGetCenter(centerReq: bigint) {
        let response: PagingResponse = new PagingResponse();
        let requestURL: string = `/api/v1/center`;

        if (centerReq !== null) {
            requestURL += `/${centerReq}`;
        }

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL)
            .then((result) => { response = <PagingResponse>result.data })
            .catch((error) => { response = error });
        return response;
    }

    public async reqGetFlexcucClass(flexcucClassReq?: bigint, requestModel?: FlexcucClassIndex.PageRequestInterface): Promise<PagingResponse> {
        let response:any = new PagingResponse();
        let requestURL: string = `/api/v1/flexcucclass`;

        if (flexcucClassReq !== null) {
            requestURL += `/${flexcucClassReq}`;
        }

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL, {
                params: requestModel
            })
            .then((result) => { response = <PagingResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 오류가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

    public async reqPostFlexcucClass(cookClassIdx: bigint, memberIdx: bigint|any, flexcucReq: any): Promise<BaseResponse> {
        let response: any = new BaseResponse();
        let requestURL: string = `/api/v1/flexcucclass/${cookClassIdx}/member/${memberIdx}`;

        await ApiBaseModule
            .axiosDefault()
            .post(requestURL, flexcucReq)
            .then((result) => { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 오류가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

}