import axios, {AxiosInstance, AxiosResponse} from 'axios';
import * as ReleaseUtil from './ReleaseUtil';
import {BaseResponse} from "./BaseResponse";

export enum REQUEST_RESULT {
    SUCCESS = "200",
    ERROR_WITH_MESSAGE = "405",
    AUTH_ERROR = "401"
}

const BASE_URL = ReleaseUtil.getBaseURL();

const DEFAULT_HEADER = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
};

const axiosRequest = axios.create({
    baseURL: BASE_URL
});

function responseInterceptors(customAxios: AxiosInstance) {
    customAxios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            let baseRes: BaseResponse = new BaseResponse();
            baseRes.setRequestError();

            return Promise.reject(baseRes);
        }
    );

    return customAxios;
}

export function axiosDefault() {
    let axiosDefault = axios.create({
        baseURL: BASE_URL,
        headers: DEFAULT_HEADER
    });

    return responseInterceptors(axiosDefault);
}