import PromotionModule from "../Modules/PromotionModule";
import * as Mustache from 'mustache';
import {PagingResponse} from "../Modules/BaseResponse";

const $ = jQuery;
const UI = globalThis.UI;

export default class PromotionIndex {

    public promotionModule = new PromotionModule();

    public menu_list: Array<any> = []

    public constructor(props?: any) {
    }

    public async promotionRegistry(type: number)
    {
        if (!window.event.isTrusted) {
            return false;
        }

        let formData: any   = type == 1
            ? $('form[name=quizForm]').serializeArray()
            : $('form[name=eventForm]').serializeArray();

        let request: any    = {};
        let $validUI: any   = {};
        var txt_chk = /^[가-힣a-zA-Z]+$/; //한글 영문 띄어쓰기
        var num_chk = /^[0-9]*$/; //숫자만

        formData = await formData.map((item: any) => {
            request[item.name]  = item.value;
            $validUI[item.name] = $('input[name='+item.name+']')
                .parent()
                .find('.help');
        });
        console.log("길이", request.REG_PHONE.toString().length);

        if ( type === 3 ) {
            if ( !request.REG_WORD1 || !request.REG_WORD2 ) {
                alert("당탈출 코드를 입력해주세요.");
                return false;
            }

            if ( (request.REG_WORD1+request.REG_WORD2) != "저당") {
                alert("안타깝게 오답을 작성해주셨군요!\n최후의 힌트를 보시고 정답을 입력해주세요.");
                return false;
            }
        }

        if ( type === 4 ) {
            request.ADD_FIELD = { menu_01: request.MENU_01, menu_02: request.MENU_02, menu_03: request.MENU_03}
        }

        if ( !request.REG_NAME ) {
            alert("이름을 입력해 주세요.");
            // $('input[name=REG_NAME]').focus((e) => {
            //     e.preventDefault();
            //     e.target.focus({preventScroll: true});
            // });
            return false;
        }else if( !txt_chk.test(request.REG_NAME) ){
            alert("이름을 정확히 입력해주세요.");
            // $('input[name=REG_NAME]').focus((e) => {
            //     e.preventDefault();
            //     e.target.focus({preventScroll: true});
            // });
            return false;
        }

        if ( !request.REG_PHONE ) {
            alert("'-'를 제외한 휴대폰 번호를 입력해주세요.");
            // $('input[name=REG_PHONE]').focus((e) => {
            //     e.preventDefault();
            //     e.target.focus({preventScroll: true});
            // });
            return false;
        }else if( !num_chk.test(request.REG_PHONE) ){
            alert("휴대폰 번호를 정확히 입력해주세요");
            // $('input[name=REG_PHONE]').focus((e) => {
            //     e.preventDefault();
            //     e.target.focus({preventScroll: true});
            // });
            return false;
        }else if(request.REG_PHONE.toString().length < 9){
            alert("휴대폰 번호를 정확히 입력해주세요");
            // $('input[name=REG_PHONE]').focus((e) => {
            //     e.preventDefault();
            //     e.target.focus({preventScroll: true});
            // });
            return false;
        }

        if ( !request.AGREE1 || request.AGREE1.toString() == "0") {
            alert("개인정보 수집 및 이용에 동의 해주세요.");
            return false;
        }

        if ( !request.AGREE2 || request.AGREE2.toString() == "0") {
            alert("마케팅 활용에 동의 해주세요.");
            return false;
        }

        if(type === 4) {
            // 오늘의 식탁 이벤트 ADN 스크립트(버튼 완료형)
            adn_btn_ok('100112','types3');
        }

        await this.promotionModule.reqPostPromotionRegister(request)
            .then((response) => {
                if (response.code == '1') {
                    
                    // alert(type == 1
                    //     ? "이벤트 응모 완료 되었습니다."
                    //     : "이벤트 응모가 완료되었습니다. 감사합니다.");
                        
                    if (type == 1) {
                        // alert("이벤트 응모 완료 되었습니다.");

                        // $('#quiz-pop').stop().fadeOut();
                        $('#quiz-pop').hide();
                        //$('#exp-step1').stop().fadeIn();
                        $('#endev1-pop').show();
                         $('body').removeClass('ovh');
                    }
                    if (type == 2) {
                        alert("이벤트 응모가 완료되었습니다. 감사합니다.");
                        // $('#exp-form').stop().fadeOut();
                        // $('#member-pop').stop().fadeIn();
                        $('#exp-form').hide();
                        $('#member-pop').show();
                    }
                    if(type == 3) {
                        $('#pop-form').fadeOut();
                        $('#pop-complete').fadeIn();

                        $('form[name="eventForm"] input[type="text"]').val('');
                        $('form[name="eventForm"] input[type="tel"]').val('');
                        $('form[name="eventForm"] input[type="hidden"]:not([name="PROMOTION_IDX"]):not([name="CLIENT_IP"])').val('');
                        $('form[name="eventForm"] input[type="checkbox"]').prop('checked', false);
                    }
                    if(type == 4) {
                        $('#pop-form').find('.btn-close').off('click');
                        $('#pop-form').fadeOut();
                        $('#pop-complete').fadeIn();

                        $('form[name="eventForm"] input[type="text"]').val('');
                        $('form[name="eventForm"] input[type="tel"]').val('');
                        $('form[name="eventForm"] input[type="checkbox"]').prop('checked', false);
                    }
                } else if (response.code == '-2') {
                    alert(response.message);
                    return false;
                } else if(response.code == '-3') {
                    $('#pop-form').find('.btn-close').off('click');
                    $('#pop-form').fadeOut();
                    $('.pop-limit').fadeIn();
                } else {
                    alert("응모에 실패했습니다. 새로고침하여 다시 시도해주세요.");
                    return false;
                }
            });
    }

    public async getMenuList() {
        await this.promotionModule.reqGetMenuList()
            .then((response) => {
                this.menu_list = response.data
            })
    }
}

