import _ from 'lodash';
import * as Mustache from 'mustache';
import StyleClubModule from '../Modules/StyleClubModule';
import {BaseResponse} from "../Modules/BaseResponse";

const $     = jQuery;
const UI    = globalThis.UI;

export default class StyleClubIndex {

    private stylecucModule = new StyleClubModule();

    public getCurrentMemberType(type: any) {
        if (typeof type === "string") {
            switch (type.toLowerCase()) {
                case 'star': return 1;
                case 'vip': return 2;
                case 'vvip': return 3;
            }
        } else {
            switch (parseInt(type)) {
                case 1: return 'Star';
                case 2: return 'VIP';
                case 3: return 'VIP';
            }
        }
    }

    public async openRegistModal(type: number) {

        let memberIdx: bigint = <bigint><unknown>$('#memberIdx').val();

        let isGo = true;

        if (memberIdx) {
            await this.stylecucModule.checkMember(memberIdx, type)
                .then((response) => {
                    if (response.code == "-1") {
                        alert(response.message);
                        isGo = false;
                    }
                });
        }

        if (isGo) {
            let agreeTemplate = document.getElementById('agreeTemplate');
            let $ui = $('#registPopArea');

            let html = Mustache.render(agreeTemplate.innerHTML, {
                REGIST_TYPE: this.getCurrentMemberType(type),
                IS_VIP: type == 2,
            });

            $ui.html(html).addClass('pop-agree').data('regist-type', type);
            UI.layerPopUp({selId: '#registPopArea'});
        }
    }

    public fnPopup() {

        if (!$('#agree').prop('checked')) {
            alert("클럽혜택 제공 및 정보 보유 기간에 동의해주세요.");
            return false;
        }

        if (!($('#agree2').prop('checked'))) {
            alert("마케팅 동의에 체크해주세요.");
            return false;
        }

        var win = window.open(
            '',
            'popupChk',
            'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
        );

        document.forms[0].action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.forms[0].target = "popupChk";
        document.forms[0].submit();

        return win;
    }

    public isIE() {
        var agent = navigator.userAgent.toLowerCase();

        if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) ) {
            return true;
        } else {
            return false;
        }
    }

    public async niceAuthCallback(data: any) {

        //UI.closeLayer('#registPopArea');

        if (!data.decodeData) {
            if (data.code && data.code == -1) {
                alert(data.message);
                return false;
            }
            alert("인증에 실패하였습니다.");
            return false;
        }

        let applyTemplate = document.getElementById("joinTemplate");
        let $ui = $('#registPopArea');
        let value = data.decodeData;
        let isGo = true;


        if (!this.isIE()) {
            console.log('isIEIE');
            data.decodeData['MEMBER_TYPE'] = $ui.data('regist-type');
            await this.stylecucModule.checkMemberForObject(data.decodeData)
                .then((response) => {
                    if (response.code == "-1") {
                        alert(response.message);
                        isGo = false;
                    } else if (response.code == "1") {
                        if (response.data['MEMBER_IDX']) {
                            let $memberIdx = $('<input type="hidden" id="memberIdx">').val(response.data['MEMBER_IDX']);
                            $('#wrap').append($memberIdx);
                        }

                        if (response.data['MEMBER_TYPE']) {
                            let $memberType = $('<input type="hidden" id="memberType">').val(response.data['MEMBER_TYPE']);
                            $('#wrap').append($memberType);
                        }
                    }
                });
        }

        if (isGo) {
            value['REGIST_TYPE'] = this.getCurrentMemberType($ui.data('regist-type'));
            value['IS_VIP'] = $ui.data('regist-type') == 2 ? true : false;
            value['MEMBER_TYPE'] = $ui.data('regist-type');
            value['GENDER_STRING'] = value['gender'] == 1 ? '남' : '여';
            value['MEMBER_IDX'] = document.getElementById("memberIdx") != null ? $('#memberIdx').val() : false;
            value['IS_LET_VVIP'] = document.getElementById("memberType") != null && $ui.data('regist-type') == 2 ? true : false;

            let html = Mustache.render(applyTemplate.innerHTML, value);

            $ui.html(html).removeClass('pop-agree').addClass('pop-join');
            UI.layerPopUp({selId: '#registPopArea'});
        }
    }

    public async postRegistMember() {
        let formData: any = $('#memberData').serializeArray()
        let request: any = {};

        formData = await formData.map((item) => {
            request[item.name] = item.value;
        });

        let isValid = document.getElementById('memberType')
            && document.getElementById('memberType').value != 'VIP';

        if (!isValid) {
            if (!request.MEMBER_NAME || !request.BIRTH_DATE || !request.GENDER || !request.PHONE) {
                alert("인증정보가 누락되었습니다. 다시 시도 해주세요.");
                return false;
            }

            if (!request.EMAIL) {
                $('input[name=EMAIL]').parent().find('.caution-text').show();
                return false;
            } else {
                $('input[name=EMAIL]').parent().find('.caution-text').hide();
            }

            if (!request.POST_CODE) {
                $('input[name=ROAD_ADDRESS]').parent().find('.caution-text').show();
                return false;
            } else {
                $('input[name=ROAD_ADDRESS]').parent().find('.caution-text').hide();
            }

            if (!request.ROAD_ADDRESS) {
                $('input[name=ROAD_ADDRESS]').parent().find('.caution-text').show();
                return false;
            } else {
                $('input[name=ROAD_ADDRESS]').parent().find('.caution-text').hide();
            }

            if (!request.ADDRESS) {
                $('input[name=ADDRESS]').parent().find('.caution-text').show();
                return false;
            } else {
                $('input[name=ADDRESS]').parent().find('.caution-text').hide();
            }

            if (!request.IS_COOKING_CLASS) {
                $('select[name=IS_COOKING_CLASS]').parent().find('.caution-text').show();
                return false;
            } else {
                $('select[name=IS_COOKING_CLASS]').parent().find('.caution-text').hide();
            }
        }

        if (request.MEMBER_TYPE == 2) {
            if (!request.PRODUCT_SERIAL) {
                $('input[name=PRODUCT_SERIAL]').parent().find('.caution-text').show();
                return false;
            } else {
                $('input[name=PRODUCT_SERIAL]').parent().find('.caution-text').hide();
            }
        }

        await this.stylecucModule.postRegistMember(request)
            .then(async (response) => {
                if (response.code == "1") {

                    UI.closeLayer('#registPopArea');

                    await setTimeout(() => {
                        var template;

                        if (request.MEMBER_TYPE == 1) {
                            template = document.getElementById('joinEnd2Template');
                        } else {
                            template = document.getElementById('joinEnd1Template');
                        }

                        let $ui = $('#registPopArea');

                        $ui.html(Mustache.render(template.innerHTML, {})).removeClass('pop-join').addClass('pop-join-end');

                        UI.layerPopUp({selId: '#registPopArea'});

                    }, 300);
                } else if (response.code == "23000") {
                    alert(response.message);
                    return false;
                } else {
                    $(`input[name=${response.data}]`).parent().find('.caution-text').show();
                    $(`select[name=${response.data}]`).parent().find('.caution-text').show();

                    if (response.data.toString() == 'IS_AUTH') {
                        alert(response.message);
                    }
                    return false;
                }
            });
    }

}