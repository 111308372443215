import _ from 'lodash';
import * as Mustache from 'mustache';
import EventModule from "../Modules/EventModule";
import { BaseResponse, PagingResponse } from "../Modules/BaseResponse";
import FlexcucClassModule from "../Modules/FlexcucClassModule";
import MemberModule from "../Modules/MemberModule";

const $ = jQuery;
const UI = globalThis.UI;

export interface PageRequestInterface {
      page?: number,
      offset?: number,
      category?: string,
      searchText?: string
}

export default class FlexcucClassIndex {

      private memberModule = new MemberModule();
      private flexcucClassModule = new FlexcucClassModule();

      private memberIdx: bigint | number;
      private memberType: string;

      public requestModel: PageRequestInterface = {
            page: 0,
            offset: 3,
            category: '',
            searchText: '',
      };

      public kakaoMap: any;

      /**
       *
       * @param props
       */
      public constructor(props?: any) {
            this.memberIdx = parseInt($('#memberIdx').val().toString());

            if (this.memberIdx) {
                  this.memberModule.reqGetMemberLevel(this.memberIdx)
                        .then((response: any) => {
                              if (response.code == '1') {
                                    this.memberType = response.data.MEMBER_TYPE;
                              }
                        });
            }
      }

      /**
       *
       * @param event
       */
      public onLoad(event: Event) {
      }

      public fnPopup() {
            var win = window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

            document.forms[0].action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
            document.forms[0].target = "popupChk";
            document.forms[0].submit();

            return win;
      }

      /**
       *
       */
      public clearCallback() {
            // 현재 상태 (인증여부) 체크
            this.memberModule.regSessionCheck()
                  .then((response) => {
                        if (response.code === "1") {
                              location.reload();
                        }
                  })
                  .catch((error) => {
                        alert("서버와 통신중 오류가 발생했습니다.");
                        console.error("reqSessionCheck::", error);
                  });
      }

      /**
       *
       * @param mapString
       */
      public async openMapModal(centerIdx: bigint) {
            await this.flexcucClassModule.reqGetCenter(centerIdx)
                  .then(async (response: any) => {
                        var template = document.getElementById('mapModalTemplate');
                        var value = response.data;

                        value['IS_PARKING'] = value['IS_PARKING'] ? '가능' : '불가능';

                        var compile = Mustache.render(template.innerHTML, value);
                        $('#pop-map').html(compile);

                        var mapString = _.split(value['MAP_STRING'], ',');
                        var markerPosition = new globalThis.kakao.maps.LatLng(mapString[0], mapString[1]);

                        var marker = new globalThis.kakao.maps.Marker({
                              position: markerPosition
                        })

                        UI.layerPopUp({ selId: '#pop-map' });

                        await setTimeout(() => {
                              this.kakaoMap = new globalThis.kakao.maps.Map(

                                    document.getElementById('map-area'),
                                    {
                                          level: 3,
                                          center: new globalThis.kakao.maps.LatLng(mapString[0], mapString[1])
                                    }
                              );

                              marker.setMap(this.kakaoMap);
                        }, 300)
                  })
      }

      /**
       *
       * @param searchText
       */
      public async onSearchText(searchText?: string) {
            let request: PageRequestInterface = {
                  category: this.requestModel.category,
                  searchText: searchText
            };

            UI.loadingControl();

            await this.flexcucClassModule.reqGetFlexcucClass(null, request)
                  .then((response: PagingResponse) => {
                        if (response.data.rows) {
                              let $listArea = $('.center-list');
                              $('.search-none').remove();
                              $listArea.empty();

                              this.requestModel = {
                                    page: 1,
                                    offset: 3,
                                    category: this.requestModel.category,
                                    searchText: searchText
                              }

                              if (response.data.count <= (this.requestModel.page * this.requestModel.offset) + 1
                                    || this.requestModel.offset > response.data.rows.length) {
                                    $('.btn-list-more').hide();
                              } else {
                                    $('.btn-list-more').show();
                              }

                              if (response.data.rows.length > 0) {
                                    for (const key in response.data.rows) {
                                          let template = document.getElementById('flexcucClassListItemTemplate');

                                          let value = response.data.rows[key];
                                          value['CLASS_COUNT'] = value['COOK_CLASSES'].length;
                                          value['IS_PARKING'] = value['IS_PARKING'] ? '가능' : '불가능';
                                          value['COOK_CLASSES'].map(function (item) {
                                                item['CLASS_DATE'] = item['CLASS_DATE'].substr(0, 10);
                                                item['IS_VIP'] = item['IS_VIP'] == '1';
                                                return item;
                                          });

                                          let html = Mustache.render(template.innerHTML, value);

                                          $listArea.append(html);
                                    }
                              } else {
                                    let template = document.getElementById('flexcucListNotFoundTemplate');
                                    $listArea.parent().append(template.innerHTML);
                              }

                              $('.center-list .btn-center-detail').click(function (e) {
                                    e.preventDefault();
                                    $(this).closest('li').toggleClass('open');
                              });
                        }

                        UI.loadingControl('close');

                  });
      }

      // TODO :: 유틸 모듈로 뺄것.
      public notFoundMember(message, redirect) {
            if (confirm(message)) {
                  location.href = redirect;
            }
      }

      public async openFlexcucHistoryModal(memberIdx?: bigint) {

            if (!memberIdx) {
                  memberIdx = this.memberIdx;
            } else {
                  this.memberIdx = memberIdx;
            }

            await this.flexcucClassModule.reqGetFlexcucClassHistory(memberIdx)
                  .then((response: BaseResponse) => {
                        let value = response.data;
                        let template = document.getElementById('flexcucClassHistoryTemplate');

                        for (const key in value) {
                              let data = value[key];

                              data['CLASS_DATE'] = data['CLASS_DATE'].substr(0, 10);
                              let classDate = new Date(data['CLASS_DATE'].substr(0, 10));

                              data['IS_COMPLETE'] = new Date() >= classDate;
                              classDate = new Date(classDate.setDate(classDate.getDate() - 3));

                              data['IS_USE_CANCEL'] = new Date() < classDate;

                              if (data['CANCEL_DATE']) {
                                    data['CLASS'] = 'cancel';
                              } else if (data['IS_COMPLETE']) {
                                    data['CLASS'] = 'end';
                              } else if (!data['IS_USE_CANCEL']) {
                                    data['CLASS'] = 'dday';
                              } else {
                                    data['CLASS'] = '';
                              }
                        }

                        value['ITEMS'] = value;
                        let html = Mustache.render(template.innerHTML, value);

                        $('#pop-history').html(html);
                        UI.layerPopUp({ selId: '#pop-history' });
                  });
      }

      public async openApplyModal(cookClassIdx: bigint) {
            let totalCoupon: number = parseInt($('#totalCoupon').text(), 10);
            if (totalCoupon < 1) {
                  alert("보유한 수강권을 소진하여 클래스 신청을 할 수 없습니다.");
                  return false;
            }
            await this.flexcucClassModule.reqGetFlexcucClass(cookClassIdx)
                  .then(async (response) => {
                        let template = document.getElementById('flexcucClassApplyTemplate');
                        let value = response.data.rows[0];

                        value['COOK_CLASSES'].map(function (item) {
                              item['CLASS_DATE'] = item['CLASS_DATE'].substr(0, 10);
                              item['IS_VIP'] = item['IS_VIP'].toString() == '1';
                              return item;
                        });

                        value['COOK_CLASSES'] = value['COOK_CLASSES'][0];
                        value['IS_PARKING'] = value['IS_PARKING'] ? '가능' : '불가능';

                        let html = Mustache.render(template.innerHTML, value);
                        $('#pop-apply').html(html);

                        var mapString = _.split(value['MAP_STRING'], ',');
                        var markerPosition = new globalThis.kakao.maps.LatLng(mapString[0], mapString[1]);

                        var marker = new globalThis.kakao.maps.Marker({
                              position: markerPosition
                        })

                        UI.layerPopUp({ selId: '#pop-apply' });

                        await setTimeout(() => {
                              this.kakaoMap = new globalThis.kakao.maps.Map(

                                    document.getElementById('map-area2'),
                                    {
                                          level: 3,
                                          center: new globalThis.kakao.maps.LatLng(mapString[0], mapString[1])
                                    }
                              );

                              marker.setMap(this.kakaoMap);
                        }, 300)
                  });
      }

      public async closeCancelInfoModal() {
            await UI.closeLayer('#pop-class2');
            await this.openFlexcucHistoryModal();
      }

      public async openFlexcucClassCancel(cookClassMemberIdx) {

            $('#pop-history').data(
                  'cook-class-member-idx',
                  cookClassMemberIdx
            );

            UI
                  .closeLayer('#pop-history')
                  .layerPopUp({ selId: '#pop-class2' });
      }

      public async cancelComplete() {

            let cookClassMemberIdx = $('#pop-history').data('cook-class-member-idx');
            let cancelInfo = $('#pop-class2 #cancelInfo').val();

            if (!cancelInfo) {
                  alert("취소 사유를 선택해 주세요.");
                  return false;
            }

            let request: any = {
                  COOK_CLASS_MEMBER_IDX: cookClassMemberIdx,
                  CANCEL_INFO: cancelInfo
            }

            await this.flexcucClassModule.reqPatchFlexcucClassMember(request)
                  .then(async (response) => {
                        console.log(response);
                        if (response.code == '1') {
                              let template = document.getElementById('pop-class3');

                              await this.memberModule.reqGetCoupon(this.memberIdx)
                                    .then((response) => {
                                          let template = document.getElementById('pop-class3');

                                          let value = {
                                                COUPON_COUNT: response.data.length
                                          }

                                          let html = Mustache.render(template.innerHTML, value);
                                          $('#pop-class3').html(html);

                                          UI
                                                .closeLayer('#pop-class2')
                                                .layerPopUp({ selId: '#pop-class3' });
                                    })
                        } else {
                              alert("이미 취소된 클래스 입니다.");
                              UI.closeLayer('#pop-class2');
                              console.error(response);
                        }
                  });
      }

      public openFlexcucClassCouponRegistryModal(memberIdx: bigint) {
            let template = document.getElementById('pop-class1').innerHTML;
            let html = Mustache.render(template, { MEMBER_IDX: memberIdx });

            $('#pop-class1').html(html);

            UI.layerPopUp({ selId: '#pop-class1' });
      }

      public async postMemberCoupon(memberIdx: bigint) {
            let couponCode = $('#coupon_code').val();

            if (!couponCode) {
                  alert("수강권 번호를 입력해 주세요.");
                  return false;
            }

            UI.loadingControl();

            await this.memberModule.reqPostMemberCoupon(memberIdx, { COUPON_CODE: couponCode })
                  .then((response) => {
                        if (response.code == '1') {
                              alert("정상적으로 등록되었습니다.");
                              location.reload();
                        } else {
                              if (response.message) {
                                    alert(response.message);
                              } else {
                                    alert("이미 등록된 수강권 입니다.");
                              }
                        }

                        UI.loadingControl('close');
                  });
      }

      public async applyFlexcucClass(cookClassIdx: bigint) {
            let $ui = $('#pop-apply .amount-wrap strong');

            let memberIdx = $('#memberIdx').val();
            let memberType = $('#memberType').val();
            let isVip: boolean = $('.popup-wrap').data('is-vip');

            let personal: number = $ui.data('count');
            let maxPersona: number = $ui.data('max-count');
            let totalCoupon: number = parseInt($('#totalCoupon').text(), 10);

            if (!memberIdx) {
                  if (confirm("StyleCuc Club Member만 플렉스쿡 클래스 수강이 가능합니다.\n로그인 하시겠습니까 ?")) {
                        this.fnPopup();
                  }

                  return false;
            }

            if (isVip && memberType === 'Star') {
                  alert("해당 플렉스쿡 클래스는 VIP member만 신청 가능합니다.");
                  return false;
            }

            if (personal >= maxPersona) {
                  alert("현재 사용 신청 가능한 수 보다 많은 참가자를 신청할수 없습니다.");
                  return false;
            }
            if (totalCoupon < 1) {
                  alert("보유한 수강권을 소진하여 클래스 신청을 할 수 없습니다.");
                  return false;
            }

            if (confirm(`현재 ${personal} 명을 선택하셨습니다. 신청 하시겠습니까 ?`)) {

                  let flexcucReq: any = {
                        PERSONAL: personal
                  }

                  if (!cookClassIdx || !memberIdx) {
                        alert("올바른 신청경로가 아닙니다. 새로고침하여 재접속 해주세요.");
                        return false;
                  }

                  UI.loadingControl();

                  await this.flexcucClassModule.reqPostFlexcucClass(cookClassIdx, memberIdx, flexcucReq)
                        .then((response) => {
                              if (response.code == '1') {
                                    alert("신청이 완료 되었습니다. \n클래스 시작 3일전, 정원 미달 시 클래스가 취소될 수 있으며, 취소는 시작 4일전 까지만 가능합니다.");
                                    UI.closeLayer('#pop-apply');
                                    location.reload();
                              } else {
                                    if (response.message) {
                                          alert(response.message);
                                    } else {
                                          alert("이미 신청하신 클래스 입니다.");
                                    }
                              }

                              UI.loadingControl('close');
                        });
            }
      }

      public applyModalAddBtnEvent() {
            let $ui = $('#pop-apply .amount-wrap').find('strong');
            let count: number = $ui.data('count');
            let maxCount: number;

            switch (this.memberType) {
                  case 'Star':
                        maxCount = 1;
                        break;
                  case 'VIP':
                  case 'VVIP':
                        maxCount = 4;
                        break;
                  default:
                        maxCount = 3;
                        break;
            }

            if (count >= maxCount) {
                  return false;
            }

            $ui.text((++count) + "명").data('count', count);
      }

      public applyModalMinBtnEvent() {
            let $ui = $('#pop-apply .amount-wrap').find('strong');
            let count = $ui.data('count');

            if (count < 2) {
                  return false;
            }

            $ui.text((--count) + "명").data('count', count);
      }

}