import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import { BaseResponse, PagingResponse } from "./BaseResponse";
import * as ServiceIndex from "../Pages/ServiceIndex";
import { PageRequestInterface } from "../Pages/ServiceIndex";

export default class ServiceModule {

      public constructor() {

      }

      public async reqGetNotice(boardIdx): Promise<BaseResponse> {
            let response: any = new BaseResponse();
            let requestURL: string = `/api/v1/board/${boardIdx}`;

            await ApiBaseModule
                  .axiosDefault()
                  .get(requestURL)
                  .then((result) => { response = <BaseResponse>result.data })
                  .catch((error) => { response = error });

            return response;
      }

      public async reqGetMedia(req: PageRequestInterface): Promise<PagingResponse> {
            let response: PagingResponse = new PagingResponse();
            let requestURL: string = `/api/v1/media`;

            await ApiBaseModule
                  .axiosDefault()
                  .get(requestURL, {
                        params: req
                  })
                  .then((result) => {
                        response = <PagingResponse>result.data;
                  })
                  .catch((error) => {
                        alert("서버와의 통신중 문제가 발생했습니다.");
                        console.error(error);
                  });
            return response;
      }

      public async reqGetBoard(req: any): Promise<PagingResponse> {
            let response: any = new PagingResponse();
            let requestURL: string = `/api/v1/board`;

            await ApiBaseModule
                  .axiosDefault()
                  .get(requestURL, {
                        params: req
                  })
                  .then((result) => {
                        response = <PagingResponse>result.data;
                  })
                  .catch((error) => {
                        alert("서버와의 통신중 문제가 발생했습니다.");
                        console.error(error);
                  })
            return response;
      }

      public async setJoinCount(idx: string) {
            let response: any = new BaseResponse();
            let requestURL: string = `/api/v1/faq/${idx}`;

            await ApiBaseModule
                  .axiosDefault()
                  .get(requestURL)
                  .then((result) => { })
                  .catch((error) => { response = error });

            return response;
      }

      /*public async reqGetLives(liveReq?: string, requestModel?: LiveIndex.PageRequestInterface): Promise<PagingResponse> {
          let response: any = new PagingResponse();
          let requestURL: string = `/api/v1/live`;

          if (liveReq !== null) {
              requestURL += `/${liveReq}`;
          }

          await ApiBaseModule
              .axiosDefault()
              .get(requestURL, {
                  params: requestModel
              })
              .then((result) => { response = <PagingResponse>result.data })
              .catch((error) => { response = error });
          return response;
      }*/

}