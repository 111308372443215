import * as Mustache from 'mustache';
import EventModule from "../Modules/EventModule";
import MainIndex from "./MainIndex";
import {PagingResponse} from "../Modules/BaseResponse";

const $ = jQuery;
const UI = globalThis.UI;

export interface PageRequestInterface {
    page:           number,
    offset:         number,
    category?:      string,
    searchText?:    string
}

export default class EventIndex {

    public eventModule   = new EventModule();

    public requestModel: PageRequestInterface = {
        page:       1,
        offset:     6,
        category:   '',
        searchText: '',
    };

    public constructor(props?: any) {
        this.mounted();
    }

    public async mounted() {
        let pathName: string | string[] = location.pathname.split('/');
        let eventIdx: bigint = pathName[2] as unknown as bigint;

        if (eventIdx) {
            await (new MainIndex).openEventModal(eventIdx);
        }
    }

    public onLoad(event: Event) {}

    public async onSearchText(searchText?: string) {
        let request: PageRequestInterface = {
            page: 0,
            offset: 6,
            category: this.requestModel.category,
            searchText: searchText
        };

        UI.loadingControl();

        await this.eventModule.reqGetEvents(null, request)
            .then((response: PagingResponse) => {
                if (response.data.rows) {
                    let $listArea = $('.section-cont-area');
                    $listArea.empty();

                    this.requestModel = {
                        page: 1,
                        offset: 6,
                        category: this.requestModel.category,
                        searchText: searchText
                    }

                    let template = document.getElementById('eventListTemplate');
                    if (response.data.rows.length > 0) {
                        let value = {
                            EVENT_CONTENT: {
                                EVENT_LIST: response.data.rows.map((val) => {
                                    val['CLASS_NAME'] = val['TYPE'] === '이벤트' ? 'event' : 'winner';
                                    val['REG_DATE'] = val['REG_DATE'].substr(0, 10);
                                    val['START_DATE'] = val['START_DATE'].substr(0, 10);
                                    val['END_DATE'] = val['END_DATE'].substr(0, 10);
                                    val['IS_EVENT'] = val['TYPE'] == '이벤트';
                                    val['IS_LINK'] = (val['IS_PROMOTION'] === '1')
                                    return val;
                                }),
                                IS_APPEND: !(response.data.count <= (this.requestModel.page * this.requestModel.offset)
                                    || this.requestModel.offset > response.data.rows.length)
                            }
                        };

                        let html = Mustache.render(template.innerHTML, value);
                        $listArea.html(html);
                    } else {
                        let html = Mustache.render(template.innerHTML, {EVENT_CONTENT: null});
                        $listArea.html(html);
                    }
                }

                UI.loadingControl('close');
            })
    }

    public async onChangeCategory(category?: string) {
        let request: PageRequestInterface = {
            page:   0,
            offset: 6,
            category: category,
            searchText: $('#searchText').val().toString()
        };

        UI.loadingControl();
        await this.eventModule.reqGetEvents(null, request)
            .then((response: PagingResponse) => {
                if (response.data.rows) {
                    let $listArea = $('.section-cont-area');
                    $listArea.empty();

                    this.requestModel = {
                        page:   1,
                        offset: 6,
                        category: category,
                        searchText: $('#searchText').val().toString()
                    }

                    let template = document.getElementById('eventListTemplate');

                    if (response.data.rows.length > 0) {
                        let value = {
                            EVENT_CONTENT: {
                                EVENT_LIST: response.data.rows.map((val) => {
                                    val['CLASS_NAME'] = val['TYPE'] === '이벤트' ? 'event' : 'winner';
                                    val['REG_DATE'] = val['REG_DATE'].substr(0, 10);
                                    val['START_DATE'] = val['START_DATE'].substr(0, 10);
                                    val['END_DATE'] = val['END_DATE'].substr(0, 10);
                                    val['IS_EVENT'] = val['TYPE'] === '이벤트'
                                    val['IS_LINK'] = (val['IS_PROMOTION'] === '1')
                                    return val;
                                }),
                                IS_APPEND: response.data.count > response.data.rows.length
                            }
                        };

                        let html = Mustache.render(template.innerHTML, value);
                        $listArea.html(html);
                    } else {
                        let html = Mustache.render(template.innerHTML, {EVENT_CONTENT: null});
                        $listArea.html(html);
                    }
                }

                UI.loadingControl('close');
            });
    }

    public async addMore() {
        let template = document.getElementById('eventListItemTemplate');

        UI.loadingControl();

        let response =
            await this.eventModule.reqGetEvents(null, this.requestModel)
                .then((response) => {
                    if (response.data) {
                        for (const key  in response.data.rows) {
                            const value = response.data.rows[key];
                            value['CLASS_NAME']
                                = value['TYPE'] === '이벤트' ? 'event' : 'winner';
                            value['REG_DATE'] = value['REG_DATE'].substr(0, 10);
                            value['START_DATE'] = value['START_DATE'].substr(0, 10);
                            value['END_DATE'] = value['END_DATE'].substr(0, 10);
                            value['IS_EVENT'] = value['TYPE'] === '이벤트';
                            value['IS_LINK'] = (value['IS_PROMOTION'] === '1')

                            let html = Mustache.render(template.innerHTML, value);

                            $('.list-area').append(html);
                        }

                        if (response.data.count <= (this.requestModel.page * this.requestModel.offset) + 1
                            || this.requestModel.offset > response.data.rows.length) {
                            $('.btn-more-area').hide();
                        }
                    }
                    UI.loadingControl('close');
                    this.requestModel.page++;
                });
    }
}