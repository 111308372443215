import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import {BaseResponse} from "./BaseResponse";
import {appendSuffixesIfMatch} from "ts-loader/dist/utils";
import {AxiosResponse} from "axios";

export default class PromotionModule {

    public constructor() {

    }

    public async reqPostPromotionRegister(promoReq: any): Promise<BaseResponse> {
        let response = new BaseResponse();
        let requestPath = `/api/v1/promotion`;

        await ApiBaseModule
            .axiosDefault()
            .post(requestPath, promoReq)
            .then((result) => {
                response = <BaseResponse>result.data;
            })
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

    public async reqGetMenuList(): Promise<BaseResponse> {
        let response: BaseResponse = new BaseResponse();
        let requestURL: string = `/api/v1/promotion/todaytable/menu`;

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL)
            .then((result: AxiosResponse) => { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

}