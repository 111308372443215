import "core-js/stable";

import _ from 'loadsh';

import NewsModule from './Modules/NewsModule';
import MainIndex from './Pages/MainIndex';
import LiveIndex from "./Pages/LiveIndex";
import EventIndex from './Pages/EventIndex';
import FlexcucClassIndex from "./Pages/FlexcucClassIndex";
import StyleClubIndex from './Pages/StyleClubIndex';
import RecipeIndex from "./Pages/RecipeIndex";
import ServiceIndex from "./Pages/ServiceIndex";
import FlexcucIndex from "./Pages/FlexcucIndex";
import PromotionIndex from "./Pages/PromotionIndex";

export {
    _,
    NewsModule,
    RecipeIndex,
    MainIndex,
    LiveIndex,
    EventIndex,
    FlexcucClassIndex,
    StyleClubIndex,
    ServiceIndex,
    FlexcucIndex,
    PromotionIndex
};