import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import {BaseResponse} from "./BaseResponse";

export default class StyleClubModule {

    public async checkMemberForObject(requestObj: any): Promise<BaseResponse> {
        let resultURL = '/api/v1/memberobj';
        let response: BaseResponse = new BaseResponse();

        await ApiBaseModule
            .axiosDefault()
            .get(resultURL, { params: requestObj })
            .then((result) => response = <BaseResponse>result.data)
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.");
                console.error(error);
            });

        return response;
    }

    public async checkMember(memberIdx: bigint, memberType: number): Promise<BaseResponse> {
        let resultURL = `/api/v1/member/${memberIdx}`;
        let response: BaseResponse = new BaseResponse();

        await ApiBaseModule
            .axiosDefault()
            .get(resultURL, { params: {MEMBER_TYPE: memberType} })
            .then((result) => response = <BaseResponse>result.data)
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

    public async postRegistMember(memberReq: any): Promise<BaseResponse> {
        let resultURL = `/api/v1/member`;
        let response: BaseResponse = new BaseResponse();

        await ApiBaseModule
            .axiosDefault()
            .post(resultURL, memberReq)
            .then((result) => { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.");
                console.error("postRegistMember::", error);
            });
        return response;
    }

}