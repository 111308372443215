import NewsModule from "../Modules/NewsModule";
import EventModule from "../Modules/EventModule";
import LiveModule from "../Modules/LiveModule";

import * as Mustache from 'mustache';

const $ = jQuery;

export default class MainIndex {

    public newsModule   = new NewsModule();
    public eventModule  = new EventModule();
    public liveModule   = new LiveModule();

    public constructor(props?: any) {}


    public async openNewsModal(newsIdx: string | object) {
        let template = document.getElementById('popupTemplate');
        let response = await this.newsModule.reqGetNews(newsIdx.toString());

        response.data.rows[0]['POP_TYPE'] = 'NEWS';
        response.data.rows[0]['REG_DATE'] = response.data.rows[0]['REG_DATE'].substr(0, 10);

        let html = Mustache.render(template.innerHTML, response.data.rows[0]);
        document.getElementById('popArea').innerHTML = html;
        $('#popArea').fadeIn();
        globalThis.scrollDisabled();
    }

    public async openEventModal(eventIdx) {
        let template = document.getElementById('popupTemplate');
        let response = await this.eventModule.reqGetEvents(eventIdx);

        response.data.rows[0]['POP_TYPE'] = 'EVENT';
        response.data.rows[0]['START_DATE'] = response.data.rows[0]['START_DATE'].substr(0, 10);
        response.data.rows[0]['REG_DATE'] = response.data.rows[0]['REG_DATE'].substr(0, 10);
        response.data.rows[0]['END_DATE'] = response.data.rows[0]['END_DATE'].substr(0, 10);
        response.data.rows[0]['IS_EVENT'] = response.data.rows[0]['TYPE'] == '이벤트' ? true : false;

        let html = Mustache.render(template.innerHTML, response.data.rows[0]);
        document.getElementById('popArea').innerHTML = html;
        $('#popArea').fadeIn();
        globalThis.scrollDisabled();

        let eventURL = `/event/${eventIdx}`;
        history.pushState(null, null, eventURL);
    }

    public async openLiveModal(liveIdx) {
        let template = document.getElementById('popupTemplate');
        let response = await this.liveModule.reqGetLives(liveIdx);

        response.data.rows[0]['POP_TYPE'] = 'LIVE';
        response.data.rows[0]['REG_DATE'] = response.data.rows[0]['REG_DATE'].substr(0, 10);

        let html = Mustache.render(template.innerHTML, response.data.rows[0]);
        document.getElementById('popArea').innerHTML = html;
        $('#popArea').fadeIn();
        globalThis.scrollDisabled();
    }

    public closeModal() {
        $('#popArea').fadeOut();
        globalThis.scrollEnabled();
        setTimeout(function () {
            document.getElementById('popArea').innerHTML = '';
        }, 300)

        history.pushState(null, null, '/event');
    }

}