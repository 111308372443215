import {REQUEST_RESULT} from "./ApiBaseModule";

interface IBaseRes {
    code: String;
    message: String;
}

interface IPagingRes {
    code: String;
    message: String;
    data: {
        rows: Object,
        count: Number
    }
}

export class BaseResponse implements IBaseRes {
    code:       String;
    message:    String;
    data?:      Array<Object>;

    public constructor () {
        this.code       = "";
        this.message    = "";

        this.setRequestError();
    }

    public setRequestError() {
        this.code = REQUEST_RESULT.ERROR_WITH_MESSAGE;
        this.message = "요청에 실패 하였습니다.";
    }
}

export class PagingResponse implements IPagingRes {
    code:       String;
    message:    String;
    data:       {
        rows:   Array<Object>,
        count:  Number
    }

    public constructor () {
        this.code       = "";
        this.message    = "";

        this.setRequestError();
    }

    public setRequestError() {
        this.code = REQUEST_RESULT.ERROR_WITH_MESSAGE;
        this.message = "요청에 실패 하였습니다.";
    }
}