import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import {BaseResponse, PagingResponse} from "./BaseResponse";

export default class NewsModule {

    public constructor() {

    }

    public async reqGetNews(req: string | object): Promise<PagingResponse> {
        let response: PagingResponse = new PagingResponse();
        let requestPath: string = '/api/v1/news';
        let params: object;

        console.log(typeof req);

        if (typeof req != "object") {
             requestPath += `/${req}`;
        } else {
            params = req;
        }

        await ApiBaseModule
            .axiosDefault()
            .get(requestPath, { params: params })
            .then((result) => { response = <PagingResponse>result.data })
            .catch((error) => { response = error });
        return response;
    }

}