import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import {BaseResponse, PagingResponse} from "./BaseResponse";
import * as LiveIndex from "../Pages/LiveIndex";

export default class LiveModule {

    public constructor() {

    }

    public async reqGetLives(liveReq?: string, requestModel?: LiveIndex.PageRequestInterface): Promise<PagingResponse> {
        let response: any = new PagingResponse();
        let requestURL: string = `/api/v1/live`;

        if (liveReq !== null) {
            requestURL += `/${liveReq}`;
        }

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL, {
                params: requestModel
            })
            .then((result) => { response = <PagingResponse>result.data })
            .catch((error) => { response = error });
        return response;
    }

}