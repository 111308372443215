import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import {BaseResponse, PagingResponse} from "./BaseResponse";
import * as EventIndex from "../Pages/EventIndex"

export default class EventModule {

    public constructor() {

    }

    public async reqGetEvents(eventReq?: object, requestModel?: EventIndex.PageRequestInterface): Promise<PagingResponse> {
        let response:any = new PagingResponse();
        let requestURL: string = `/api/v1/event`;

        if (eventReq !== null) {
            requestURL += `/${eventReq}`;
        }

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL, {
                params: requestModel
            })
            .then((result) => { response = <PagingResponse>result.data })
            .catch((error) => { response = error });
        return response;
    }

}