import LiveModule from "../Modules/LiveModule";

import * as Mustache from 'mustache';
import {PagingResponse} from "../Modules/BaseResponse";

const $ = jQuery;
const UI = globalThis.UI;

export interface PageRequestInterface {
    page:           number,
    offset:         number,
    category?:      string,
    searchText?:    string
}

export default class LiveIndex {

    public liveModule = new LiveModule();

    public requestModel: PageRequestInterface = {
        page:       1,
        offset:     12,
        category:   '',
        searchText: '',
    };

    public constructor(props?: any) {
        window.onload = (event: Event) =>
            this.onLoad(event);
    }

    public onLoad(event: Event) {

    }

    public async onSearchText(searchText?: string) {
        let request: PageRequestInterface = {
            page: 0,
            offset: 12,
            category: this.requestModel.category,
            searchText: searchText
        };

        UI.loadingControl();
        await this.liveModule.reqGetLives(null, request)
            .then((response: PagingResponse) => {
                if (response.data.rows) {
                    let $listArea = $('.section-cont-area');
                    $listArea.empty();

                    this.requestModel = {
                        page: 1,
                        offset: 12,
                        category: this.requestModel.category,
                        searchText: searchText
                    }

                    /*if (response.data.count <= (this.requestModel.page * this.requestModel.offset) +1
                        || this.requestModel.offset > response.data.rows.length) {
                        $('.btn-more-area').hide();
                    } else {
                        $('.btn-more-area').show();
                    }*/

                    if (response.data.rows.length > 0) {
                        let template = document.getElementById('liveListTemplate');

                        let value = {
                            LIVE_CONTENT: {
                                LIVE_LIST: response.data.rows.map((value) => {
                                    value['LINK_TYPE_CLASS'] = this.getLiveCategoryClass(value['LINK_TYPE']);
                                    value['ORIGIN_REG_DATE'] = value['ORIGIN_REG_DATE'].substr(0, 10);
                                    return value;
                                }),
                                IS_APPEND: !(response.data.count <= (this.requestModel.page * this.requestModel.offset)
                                    || this.requestModel.offset > response.data.rows.length)
                            }
                        };

                        let html = Mustache.render(template.innerHTML, value);

                        $listArea.html(html);
                    } else {
                        let template = document.getElementById('liveListTemplate');
                        let html = Mustache.render(template.innerHTML, {LIVE_CONTENT: null});
                        $listArea.html(html);
                    }
                }

                UI.loadingControl('close');
            })
    }

    public getLiveCategoryClass(name?: string): string {
        switch (name) {
            case '유튜브': return 'youtube';
            case '인스타그램': return 'insta';
            case '네이버블로그': return 'blog-naver';
            default: return 'youtube';
        }
    }

    public async onChangeCategory(category?: string) {
        let request: PageRequestInterface = {
            page:   0,
            offset: 12,
            category: category,
            searchText: $('#searchText').val().toString()
        };

        UI.loadingControl();

        await this.liveModule.reqGetLives(null, request)
            .then((response: PagingResponse) => {
                if (response.data.rows) {
                    let $listArea = $('.section-cont-area');

                    $listArea.empty();

                    this.requestModel = {
                        page:   1,
                        offset: 12,
                        category: category,
                        searchText: $('#searchText').val().toString()
                    }

                    if (response.data.count <= (this.requestModel.page * this.requestModel.offset)
                        || this.requestModel.offset > response.data.rows.length) {
                        $('.btn-more-area').hide();
                    } else {
                        $('.btn-more-area').show();
                    }

                    if (response.data.rows.length > 0) {
                        let template = document.getElementById('liveListTemplate');

                        let value = {
                            LIVE_CONTENT: {
                                LIVE_LIST: response.data.rows.map((value) => {
                                    value['LINK_TYPE_CLASS'] = this.getLiveCategoryClass(value['LINK_TYPE']);
                                    value['ORIGIN_REG_DATE'] = value['ORIGIN_REG_DATE'].substr(0, 10);
                                    return value;
                                }),
                                IS_APPEND: !(response.data.count <= (this.requestModel.page * this.requestModel.offset)
                                    || this.requestModel.offset > response.data.rows.length)
                            }
                        };

                        let html = Mustache.render(template.innerHTML, value);

                        $listArea.html(html);
                    } else {
                        let template = document.getElementById('liveListTemplate');
                        let html = Mustache.render(template.innerHTML, {LIVE_CONTENT: null});
                        $listArea.html(html);
                    }
                }

                UI.loadingControl('close');
            })
    }

    public async addMore() {
        let template = document.getElementById('liveListItemTemplate');

        UI.loadingControl();

        let response =
            await this.liveModule.reqGetLives(null, this.requestModel)
                .then((response) => {
                    if (response.data) {
                        for (const key  in response.data.rows) {
                            let value = response.data.rows[key];

                            value['LINK_TYPE_CLASS'] = this.getLiveCategoryClass(value['LINK_TYPE']);
                            value['ORIGIN_REG_DATE'] = value['ORIGIN_REG_DATE'].substr(0, 10);
                            let html = Mustache.render(template.innerHTML, value);

                            $('.list-area').append(html);
                        }

                        if (response.data.count <= (this.requestModel.page * this.requestModel.offset)
                            || this.requestModel.offset > response.data.rows.length) {
                            $('.btn-more-area').hide();
                        }
                    }

                    UI.loadingControl('close');
                    this.requestModel.page++;
                });
    }

}