import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import {BaseResponse, PagingResponse} from "./BaseResponse";
import * as FlexcucClassIndex from "../Pages/FlexcucClassIndex";

export default class MemberModule {

    public constructor() {

    }

    public async reqGetMemberLevel(memberIdx: bigint | number): Promise<BaseResponse> {
       let response: BaseResponse = new BaseResponse();
       let requestURL: string = `/api/v1/member/${memberIdx}/type`;

       await ApiBaseModule
           .axiosDefault()
           .get(requestURL)
           .then((result) => response = <BaseResponse>result.data)
           .catch((error) => {
               console.error('서버와의 통신중 문제가 발생하였습니다.');
               console.error('reqGetMemberLevel::', error);
               return false;
           });
       return response;
    }

    public async regSessionCheck(): Promise<BaseResponse> {
        let response: BaseResponse = new BaseResponse();
        let requestURL: string = `/api/v1/session`;

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL)
            .then((result) => { response = <BaseResponse>result.data })
            .catch((error) => { response = error });
        return response;
    }

    public async reqGetCoupon(memberIdx: bigint): Promise<BaseResponse> {
        let response: BaseResponse = new BaseResponse();
        let requestURL: string = `/api/v1/member/${memberIdx}/coupon`;

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL)
            .then((result)=> { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 오류가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

    public async reqPostMemberCoupon(memberIdx: bigint, req: any): Promise<BaseResponse> {
        let response: BaseResponse = new BaseResponse();
        let requestURL: string = `/api/v1/member/${memberIdx}/coupon`;

        await ApiBaseModule
            .axiosDefault()
            .post(requestURL, req)
            .then((result) => { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 오류가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

    public async reqPostMember(memberReq: any): Promise<BaseResponse> {
        let response: BaseResponse = new BaseResponse();
        let requestURL: string = `/api/v1/member`;

        await ApiBaseModule
            .axiosDefault()
            .post(requestURL, memberReq)
            .then((result) => { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 오류가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

}