import ServiceModule from "../Modules/ServiceModule";

import * as Mustache from 'mustache';
import { PagingResponse } from "../Modules/BaseResponse";

const $ = jQuery;
const UI = globalThis.UI;

export interface PageRequestInterface {
      page: number,
      offset: number,
      category?: string | number,
      type?: number,
      searchText?: string
}

export default class LiveIndex {

      public serviceModule = new ServiceModule();

      public faqRequestModel: PageRequestInterface = {
            page: 1,
            offset: 5,
            category: '',
            searchText: '',
      };

      public noticeRequestModel: PageRequestInterface = {
            page: 1,
            offset: 5,
            category: '',
            searchText: '',
      };

      public mediaRequestModel: PageRequestInterface = {
            page: 1,
            offset: 5,
      };

      public constructor(props?: any) {
      }

      public onClickFaq(obj, idx: string) {

            var selList = $(obj).closest('li');
            if (!selList.hasClass('open')) {
                  $('.faq-list li').removeClass('open');
                  if (idx != "") {
                        this.serviceModule.setJoinCount(idx);
                  }
            }
            $(obj).closest('li').toggleClass('open');
            window.event.preventDefault();
      }

      public async getMedia() {
            let request = this.mediaRequestModel;
            let response = await this.serviceModule.reqGetMedia(request)
                  .then((response) => {
                        let template = document.getElementById('mediaListItemTemplate');

                        for (const key in response.data.rows) {
                              let value = response.data.rows[key];
                              let html = Mustache.render(template.innerHTML, value);
                              $('.video-wrap .right').append(html);
                        }

                        console.log(response.data.rows.length);
                        if (response.data.rows.length === 0) {
                              console.log('unset');
                              $('.video-wrap .right').off('scroll');
                        }

                        this.mediaRequestModel.page++;
                  })
      }

      public async addMore(type: number) {

            if (type == 1) {
                  var request = this.faqRequestModel;
            } else if (type == 2) {
                  var request = this.noticeRequestModel;
            }

            request.type = type;

            var typeString;

            if (type == 1) {
                  typeString = 'faq';
            } else if (type == 2) {
                  typeString = 'notice';
            }

            let template = document.getElementById(typeString + 'ListItemTemplate');

            UI.loadingControl();

            let response =
                  await this.serviceModule.reqGetBoard(request)
                        .then((response) => {
                              if (response.data) {
                                    for (const key in response.data.rows) {
                                          let value = response.data.rows[key];

                                          value['REG_DATE'] = value['REG_DATE'].substr(0, 10);
                                          let html = Mustache.render(template.innerHTML, value);

                                          if (type == 1) {
                                                typeString = 'faq';
                                          } else if (type == 2) {
                                                typeString = 'news';
                                          }

                                          $('.' + typeString + '-list ul').append(html);
                                    }

                                    if (response.data.count <= (request.page * request.offset) + 1
                                          || request.offset > response.data.rows.length) {
                                          if (type == 1) {
                                                typeString = 'faq';
                                          } else if (type == 2) {
                                                typeString = 'notice';
                                          }
                                          $('.' + typeString + ' .btn-more-area').hide();
                                    }
                              }
                              UI.loadingControl('close');
                              request.page++;
                        });
      }

      public async onSearchText(type: number, searchText?: string) {
            let request: PageRequestInterface = {
                  page: 0,
                  offset: 5,
                  type: type,
                  searchText: searchText
            };

            UI.loadingControl();

            await this.serviceModule.reqGetBoard(request)
                  .then((response: PagingResponse) => {
                        if (response.data.rows) {
                              let $listArea = $((type == 1 ? '#faq' : '#notice') + ' .section-cont-area');
                              $listArea.empty();

                              if (type == 1) {
                                    var requestModel = this.faqRequestModel;

                                    this.faqRequestModel = {
                                          page: 1,
                                          offset: 5,
                                          type: type,
                                          searchText: searchText
                                    }
                              } else if (type == 2) {
                                    var requestModel = this.noticeRequestModel;

                                    this.noticeRequestModel = {
                                          page: 1,
                                          offset: 5,
                                          type: type,
                                          searchText: searchText
                                    }
                              }

                              if (response.data.count <= (requestModel.page * requestModel.offset) + 1
                                    || requestModel.offset > response.data.rows.length) {
                                    $('.' + (type == 1 ? 'faq' : 'news') + ' .btn-more-area').hide();
                              } else {
                                    $('.' + (type == 1 ? 'faq' : 'news') + ' .btn-more-area').show();
                              }

                              let typeString: string;
                              if (response.data.rows.length > 0) {
                                    if (type == 1) {
                                          typeString = 'faq';
                                    } else if (type == 2) {
                                          typeString = 'notice';
                                    }

                                    let template = document.getElementById(typeString + 'ListTemplate');

                                    let value = {
                                          NOTICE_ITEM: {
                                                ROWS: response.data.rows.map((value) => {
                                                      value['LINK_TYPE_CLASS'] = value['LINK_TYPE'] === '유튜브' ? 'youtube' : 'insta';
                                                      value['REG_DATE'] = value['REG_DATE'].substr(0, 10);
                                                      return value;
                                                }),
                                                IS_APPEND: !(response.data.count <= (requestModel.page * requestModel.offset)
                                                      || requestModel.offset > response.data.rows.length)
                                          },
                                          TYPE: typeString
                                    };

                                    let html = Mustache.render(template.innerHTML, value);

                                    $listArea.html(html);
                              } else {
                                    let template = document.getElementById('noticeListTemplate');
                                    let html = Mustache.render(template.innerHTML, { NOTICE_ITEM: null });
                                    $listArea.html(html);
                              }
                        }

                        UI.loadingControl('close');

                  })
      }

      public async openNoticeModal(noticeIdx) {
            let template = document.getElementById('noticePopupTemplate');

            await this.serviceModule.reqGetNotice(noticeIdx)
                  .then((response) => {
                        response.data['REG_DATE'] = response.data['REG_DATE'].substr(0, 10);

                        $('#pop-notice').html(Mustache.render(template.innerHTML, response.data));
                        $('#pop-notice').fadeIn();

                        globalThis.scrollDisabled();

                        /*UI.layerPopUp({selId:'#pop-notice'});*/
                  })
      }

      public async onChangeCategory(type: number, category?: number) {
            let request: PageRequestInterface = {
                  page: 0,
                  offset: 12,
                  type: type,
                  category: category,
                  searchText: $(`input[name='searchText[${type}]']`).val().toString()
            };

            UI.loadingControl();

            await this.serviceModule.reqGetBoard(request)
                  .then((response: PagingResponse) => {
                        let requestModel: PageRequestInterface;
                        let $listArea = $((type == 1 ? '#faq' : '#notice') + ' .section-cont-area');
                        $listArea.empty();

                        if (type == 1) {
                              requestModel = this.faqRequestModel;
                              this.faqRequestModel = {
                                    page: 1,
                                    offset: 5,
                                    type: type,
                                    category: category
                              };
                        } else if (type == 2) {
                              requestModel = this.noticeRequestModel;
                              this.noticeRequestModel = {
                                    page: 1,
                                    offset: 5,
                                    type: type,
                                    category: category
                              };
                        }

                        if (response.data.count <= (requestModel.page * requestModel.offset) + 1
                              || requestModel.offset > response.data.rows.length) {
                              $('.' + (type == 1 ? 'faq' : 'news') + ' .btn-more-area').hide();
                        } else {
                              $('.' + (type == 1 ? 'faq' : 'news') + ' .btn-more-area').show();
                        }

                        let typeString: string;
                        if (response.data.rows.length > 0) {
                              typeString = type == 1 ? 'faq' : (type == 2 ? 'notice' : '');
                              let template = document.getElementById(typeString + 'ListTemplate');

                              let value = {
                                    NOTICE_ITEM: {
                                          ROWS: response.data.rows.map((value) => {
                                                value['LINK_TYPE_CLASS'] = value['LINK_TYPE'] === '유튜브' ? 'youtube' : 'insta';
                                                value['REG_DATE'] = value['REG_DATE'].substr(0, 10);
                                                return value;
                                          }),
                                          IS_APPEND: !(response.data.count <= (requestModel.page * requestModel.offset)
                                                || requestModel.offset > response.data.rows.length)
                                    },
                                    TYPE: typeString
                              };

                              let html = Mustache.render(template.innerHTML, value);
                              $listArea.html(html);
                        } else {
                              let template = document.getElementById('noticeListTemplate');
                              let html = Mustache.render(template.innerHTML, { NOTICE_ITEM: null });
                              $listArea.html(html);
                        }
                  });

            UI.loadingControl('close');
      }


      /*public constructor(props?: any) {
          window.onload = (event: Event) =>
              this.onLoad(event);
      }

      public onLoad(event: Event) {

      }



      public async onChangeCategory(category?: string) {
          let request: PageRequestInterface = {
              page:   0,
              offset: 12,
              category: category,
              searchText: $('#searchText').val().toString()
          };

          UI.loadingControl();

          await this.liveModule.reqGetLives(null, request)
              .then((response: PagingResponse) => {
                  if (response.data.rows) {
                      let $listArea = $('.section-cont-area');

                      $listArea.empty();

                      this.requestModel = {
                          page:   1,
                          offset: 12,
                          category: category,
                          searchText: $('#searchText').val().toString()
                      }

                      if (response.data.count <= (this.requestModel.page * this.requestModel.offset) + 1
                          || this.requestModel.offset > response.data.rows.length) {
                          $('.btn-more-area').hide();
                      } else {
                          $('.btn-more-area').show();
                      }

                      if (response.data.rows.length > 0) {
                          let template = document.getElementById('liveListTemplate');

                          let value = {
                              LIVE_CONTENT: {
                                  LIVE_LIST: response.data.rows.map((value) => {
                                      value['LINK_TYPE_CLASS'] = value['LINK_TYPE'] === '유튜브' ? 'youtube' : 'insta';
                                      return value;
                                  })
                              }
                          };

                          let html = Mustache.render(template.innerHTML, value);

                          $listArea.html(html);
                      } else {
                          let template = document.getElementById('liveListTemplate');
                          let html = Mustache.render(template.innerHTML, {LIVE_CONTENT: null});
                          $listArea.html(html);
                      }
                  }

                  UI.loadingControl('close');

              })
      }

      public async addMore() {
          let template = document.getElementById('liveListItemTemplate');

          UI.loadingControl();

          let response =
              await this.liveModule.reqGetLives(null, this.requestModel)
                  .then((response) => {
                      if (response.data) {
                          for (const key  in response.data.rows) {
                              let value = response.data.rows[key];

                              value['LINK_TYPE_CLASS'] = value['LINK_TYPE'] === '유튜브' ? 'youtube' : 'insta';
                              let html = Mustache.render(template.innerHTML, value);

                              $('.list-area').append(html);
                          }

                          if (response.data.count <= (this.requestModel.page * this.requestModel.offset) + 1
                              || this.requestModel.offset > response.data.rows.length) {
                              $('.btn-more-area').hide();
                          }
                      }
                      UI.loadingControl('close');
                      this.requestModel.page++;
                  });
      }*/

}