import _ from 'loadsh';
import * as ApiBaseModule from './ApiBaseModule';
import {BaseResponse, PagingResponse} from "./BaseResponse";
import {PageRequestInterface} from "../Pages/RecipeIndex";
import {AxiosResponse} from "axios";

export default class RecipeModule {

    public constructor() {

    }

    public async reqGetRecipeList(recipeIdx: bigint, request: PageRequestInterface): Promise<PagingResponse> {
        let response: PagingResponse = new PagingResponse();
        let requestURL: string = `/api/v1/recipe`;

        if (recipeIdx !== null) {
            requestURL += `/${recipeIdx}`;
        }

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL, {
                params: request
            })
            .then((result: AxiosResponse) => { response = <PagingResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.")
                console.error(error);
            });
        return response;
    }

    public async reqGetRecipe(recipeIdx: bigint): Promise<BaseResponse> {
        let response: BaseResponse = new BaseResponse();
        let requestURL: string = `/api/v1/recipe/${recipeIdx}/step`;

        await ApiBaseModule
            .axiosDefault()
            .get(requestURL)
            .then((result: AxiosResponse) => { response = <BaseResponse>result.data })
            .catch((error) => {
                alert("서버와의 통신중 문제가 발생했습니다.");
                console.error(error);
            });
        return response;
    }

}