import _ from 'lodash';
import * as Mustache from 'mustache';
import { BaseResponse, PagingResponse } from "../Modules/BaseResponse";
import RecipeModule from "../Modules/RecipeModule";

const $ = jQuery;
const UI = globalThis.UI;

export interface PageRequestInterface {
      page?: number,
      offset?: number,
      category?: string,
      subCategory?: string,
      depth?: number,
      searchText?: string,
      type?: number
}

export default class RecipeIndex {

      private recipeModule = new RecipeModule();

      public requestModel: PageRequestInterface = {
            page: 1,
            offset: 6,
            category: '',
            searchText: '',
      };

      /**
       *
       * @param props
       */
      public constructor(props?: any) {
            this.mounted();
      }

      public onClickHash(type, obj) {
            if (type == "룩북") {
                  type = 1;
            } else if (type == "시그니처") {
                  type = 2;
            } else if (type == "라이프스타일") {
                  type = 3;
            } else if (type == "저당저염") {
                  type = 4;
            }
            let $ui = $(`input[name="searchText[${type}]"]`);
            let hash = $(obj).text().replace('#', '');

            $ui.val(hash);
            this.onSearchText(type, hash);
      }

      public async mounted() {
            let pathName: string | string[] = location.pathname.split('/');
            let recipeIdx: bigint = pathName[2] as unknown as bigint;

            if (recipeIdx) {
                  await this.openRecipeModal(recipeIdx);
            }
      }

      public async openRecipeModal(recipeIdx: bigint) {
            await this.recipeModule.reqGetRecipe(recipeIdx)
                  .then((response) => {
                        if (response.code == '1' && response.data) {
                              let template = document.getElementById('popViewTemplate');
                              let value: any = response.data;

                              value['IS_RECIPE'] = value['TYPE'] == '룩북'

                              value['LOW_TYPE_CLASS'] = (value['LOW_TYPE'] != '저염') ? 'low-sugar' : 'low-salt';

                              value.STEPS = value.STEPS.map((val) => {
                                    let iconParser = JSON.parse(val.ICON_INFO);
                                    if (iconParser != null) {
                                          val['ICONS'] = iconParser.ICONS;
                                    }
                                    return val;
                              });

                              let $ui = $('#pop-view');
                              let recipeURL = `/recipe/${value.RECIPE_IDX}`;

                              history.pushState(null, null, recipeURL);
                              $ui.html(Mustache.render(template.innerHTML, value));
                              UI.layerPopUp({
                                    selId: '#pop-view', callback: (e) => {
                                          e.preventDefault();
                                          globalThis.scrollEnabled();

                                          $('#pop-view').fadeOut().empty();

                                          history.pushState(null, null, '/recipe');
                                    }
                              });
                        }
                  })
      }

      /**
       *
       * @param type
       * @param searchText
       */
      public async onSearchText(type: number, searchText?: string) {
            let request: PageRequestInterface = {
                  page: 0,
                  offset: 6,
                  category: this.requestModel.category,
                  subCategory: this.requestModel.subCategory,
                  type: type,
                  searchText: searchText
            };

            UI.loadingControl();

            await this.recipeModule.reqGetRecipeList(null, request)
                  .then((response: PagingResponse) => {
                        if (response.data.rows) {

                              let $listArea: any;
                              if (type === 1) {
                                    $listArea = $('.section-wrap.lookbook .section-cont-area');
                              } else if (type === 2) {
                                    $listArea = $('.section-wrap.signature .section-cont-area');
                              } else if (type === 3) {
                                    $listArea = $('.section-wrap.lifestyle .section-cont-area');
                              } else {
                                    $listArea = $('.section-wrap.low-sugar-salt .section-cont-area');
                              }

                              $listArea.empty();
                              let template = document.getElementById('recipeListTemplate');

                              for (const key in response.data.rows) {
                                    response.data.rows[key]['LOW_TYPE_CLASS'] = (response.data.rows[key]['LOW_TYPE'] != '저염') ? 'low-sugar' : 'low-salt';
                              }

                              let value = {
                                    RECIPES: {
                                          ROWS: response.data.rows,
                                          IS_APPEND: true,
                                          TYPE: type
                                    }
                              }

                              this.requestModel = {
                                    page: 1,
                                    offset: 6,
                                    type: type,
                                    searchText: searchText,
                                    category: this.requestModel.category,
                                    subCategory: this.requestModel.subCategory
                              }

                              if (response.data.count < (this.requestModel.page * this.requestModel.offset) + 1
                                    || this.requestModel.offset > response.data.rows.length) {
                                    value.RECIPES.IS_APPEND = false;
                              }

                              $listArea.html(Mustache.render(template.innerHTML, value));
                        }

                        UI.loadingControl('close');

                  });
      }

      public async addMore(type) {
            let template = document.getElementById('recipeListItemTemplate');

            this.requestModel.type = type;

            UI.loadingControl();

            let response =
                  await this.recipeModule.reqGetRecipeList(null, this.requestModel)
                        .then((response) => {
                              if (response.data) {
                                    for (const key in response.data.rows) {
                                          const value = response.data.rows[key];

                                          value['LOW_TYPE_CLASS'] = (value['LOW_TYPE'] != '저염') ? 'low-sugar' : 'low-salt';


                                          let html = Mustache.render(template.innerHTML, value);

                                          if (type === 1) {
                                                $('.section-wrap.lookbook .recipe-list ul').append(html);
                                          } else if (type === 2) {
                                                $('.section-wrap.signature .recipe-list ul').append(html);
                                          } else if (type === 3) {
                                                $('.section-wrap.lifestyle .recipe-list ul').append(html);
                                          } else {
                                                $('.section-wrap.low-sugar-salt .recipe-list ul').append(html);
                                          }
                                    }

                                    if (response.data.count < (this.requestModel.page * this.requestModel.offset) + 1
                                          || this.requestModel.offset > response.data.rows.length) {
                                          if (type === 1) {
                                                $('.section-wrap.lookbook .btn-more-area').hide();
                                          } else if (type === 2) {
                                                $('.section-wrap.signature .btn-more-area').hide();
                                          } else if (type === 3) {
                                                $('.section-wrap.lifestyle .btn-more-area').hide();
                                          } else {
                                                $('.section-wrap.low-sugar-salt .btn-more-area').hide();
                                          }
                                    }
                              }

                              UI.loadingControl('close');
                              this.requestModel.page++;
                        });
      }

      public async onChangeCategory(depth: number, type: number, category?: string) {
            let request: PageRequestInterface = {
                  page: 0,
                  offset: 6,
                  type: type,
                  searchText: $('input[name="searchText[' + type + ']"]').val().toString()
            };

            if (depth === 1) {
                  request['category'] = category;
                  request['subCategory'] = this.requestModel.subCategory;
            } else if (depth === 2) {
                  request['category'] = this.requestModel.category;
                  request['subCategory'] = category;
            }

            UI.loadingControl();

            await this.recipeModule.reqGetRecipeList(null, request)
                  .then((response: PagingResponse) => {
                        if (response.data.rows) {

                              let $listArea: any;
                              if (type === 1) {
                                    $listArea = $('.section-wrap.lookbook .section-cont-area');
                              } else if (type === 2) {
                                    $listArea = $('.section-wrap.signature .section-cont-area');
                              } else if (type === 3) {
                                    $listArea = $('.section-wrap.lifestyle .section-cont-area')
                              } else {
                                    $listArea = $('.section-wrap.low-sugar-salt .section-cont-area')
                              }

                              $listArea.empty();

                              this.requestModel = {
                                    page: 1,
                                    offset: 6,
                                    type: type,
                                    searchText: $('input[name="searchText[' + type + ']"]').val().toString()
                              }

                              if (depth === 1) {
                                    this.requestModel['category'] = category;
                                    request['subCategory'] = this.requestModel.subCategory;
                              } else if (depth === 2) {
                                    request['category'] = this.requestModel.category;
                                    this.requestModel['subCategory'] = category;
                              }

                              let template = document.getElementById('recipeListTemplate');

                              if (response.data.rows.length > 0) {
                                    for (const key in response.data.rows) {
                                          response.data.rows[key]['LOW_TYPE_CLASS'] = (response.data.rows[key]['LOW_TYPE'] != '저염') ? 'low-sugar' : 'low-salt';
                                    }

                                    let value = {
                                          RECIPES: {
                                                ROWS: response.data.rows,
                                                IS_APPEND: true,
                                                TYPE: type
                                          }
                                    }

                                    if (response.data.count < (this.requestModel.page * this.requestModel.offset) + 1
                                          || this.requestModel.offset > response.data.rows.length) {
                                          value.RECIPES.IS_APPEND = false;
                                    }

                                    let html = Mustache.render(template.innerHTML, value);
                                    $listArea.html(html);
                              } else {
                                    let html = Mustache.render(template.innerHTML, { EVENT_CONTENT: null });
                                    $listArea.html(html);
                              }
                        }

                        UI.loadingControl('close');

                  });
      }

      /*public async addMore() {
          let template = document.getElementById('recipeListTemplate');

          let response =
              await this.flexcucClassModule.reqGetFlexcucClass(null, this.requestModel)
                  .then((response) => {
                      if (response.data) {
                          for (const key  in response.data.rows) {
                              const value = response.data.rows[key];

                              value['CLASS_COUNT'] = value['COOK_CLASSES'].length;
                              value['IS_PARKING'] = value['IS_PARKING'] ? '가능' : '붐가능';

                              let html = Mustache.render(template.innerHTML, value);

                              $('.center-list').append(html);
                          }

                          if (response.data.count <= ((this.requestModel.page+1) * this.requestModel.offset) + 1
                              || this.requestModel.offset > response.data.rows.length) {
                              $('.btn-list-more').hide();
                          }

                          $('.center-list .btn-center-detail').click(function(e){
                              e.preventDefault();
                              $(this).closest('li').toggleClass('open');
                          });
                      }

                      this.requestModel.page++;
                  });
      }

      /!**
       *
       * @param mapString
       *!/
      public openMapModal(centerIdx: bigint) {
          this.flexcucClassModule.reqGetCenter(centerIdx)
              .then((response: any) => {
                  let template = document.getElementById('mapModalTemplate');
                  let value = response.data;

                  value['IS_PARKING'] = value['IS_PARKING'] ? '가능' : '불가능';

                  let compile = Mustache.render(template.innerHTML, value);
                  $('#pop-map').html(compile);

                  let mapString = _.split(value['MAP_STRING'], ',');
                  this.kakaoMap = new globalThis.kakao.maps.Map(

                      document.getElementById('map-area'),
                      {
                          level: 3,
                          center: new globalThis.kakao.maps.LatLng(mapString[1], mapString[0])
                      }
                  );

                  UI.layerPopUp({selId:'#pop-map'});
              })
      }

      /!**
       *
       * @param searchText
       *!/
      public async onSearchText(searchText?: string) {
          let request: PageRequestInterface = {
              category: this.requestModel.category,
              searchText: searchText
          };

          await this.flexcucClassModule.reqGetFlexcucClass(null, request)
              .then((response: PagingResponse) => {
                  if (response.data.rows) {
                      let $listArea = $('.center-list');
                      $listArea.empty();

                      this.requestModel = {
                          page: 1,
                          offset: 3,
                          category: this.requestModel.category,
                          searchText: searchText
                      }

                      if (response.data.count <= (this.requestModel.page * this.requestModel.offset) +1
                          || this.requestModel.offset > response.data.rows.length) {
                          $('.btn-list-more').hide();
                      } else {
                          $('.btn-list-more').show();
                      }

                      for (const key in response.data.rows) {
                          let template = document.getElementById('flexcucClassListItemTemplate');

                          let value = response.data.rows[key];
                          value['CLASS_COUNT']    = value['COOK_CLASSES'].length;
                          value['IS_PARKING']     = value['IS_PARKING'] ? '가능' : '붐가능';

                          let html = Mustache.render(template.innerHTML, value);

                          $listArea.append(html);
                      }

                      $('.center-list .btn-center-detail').click(function(e){
                          e.preventDefault();
                          $(this).closest('li').toggleClass('open');
                      });
                  }
              });
      }

      // TODO :: 유틸 모듈로 뺄것.
      public notFoundMember(message, redirect) {
          if (confirm(message)) {
              location.href = redirect;
          }
      }

      public async openFlexcucHistoryModal(memberIdx?: bigint) {

          if (!memberIdx) {
              memberIdx = this.memberIdx;
          } else {
              this.memberIdx = memberIdx;
          }

          await this.flexcucClassModule.reqGetFlexcucClassHistory(memberIdx)
              .then((response: BaseResponse) => {
                  let value = response.data;
                  let template = document.getElementById('flexcucClassHistoryTemplate');

                  for (const key in value) {
                      let data = value[key];
                      let classDate = new Date(data['CLASS_DATE']);

                      data['IS_COMPLETE'] = new Date() >= classDate;
                      classDate = new Date(classDate.setDate(classDate.getDate()-4));

                      data['IS_USE_CANCEL'] = new Date() < classDate;

                      if (data['CANCEL_DATE']) {
                          data['CLASS'] = 'cancel';
                      } else if (data['IS_COMPLETE']) {
                          data['CLASS'] = 'end';
                      } else if (!data['IS_USE_CANCEL']) {
                          data['CLASS'] = 'dday';
                      } else {
                          data['CLASS'] = '';
                      }
                  }

                  value['ITEMS'] = value;
                  let html = Mustache.render(template.innerHTML, value);

                  $('#pop-history').html(html);
                  UI.layerPopUp({selId: '#pop-history'});
              });
      }

      public async openApplyModal(cookClassIdx: bigint) {
          await this.flexcucClassModule.reqGetFlexcucClass(cookClassIdx)
              .then((response) => {
                  let template = document.getElementById('flexcucClassApplyTemplate');
                  let value = response.data.rows[0];

                  value['COOK_CLASSES']   = value['COOK_CLASSES'][0];
                  value['IS_PARKING']     = value['IS_PARKING'] ? '가능' : '불가능';

                  let html = Mustache.render(template.innerHTML, value);
                  $('#pop-apply').html(html);

                  UI.layerPopUp({selId:'#pop-apply'});
              });
      }

      public async closeCancelInfoModal() {
          await UI.closeLayer('#pop-class2');
          await this.openFlexcucHistoryModal();
      }

      public async openFlexcucClassCancel(cookClassMemberIdx) {

          $('#pop-history').data(
              'cook-class-member-idx',
              cookClassMemberIdx
          );

          UI
              .closeLayer('#pop-history')
              .layerPopUp({selId: '#pop-class2'});
      }

      public async cancelComplete() {

          let cookClassMemberIdx = $('#pop-history').data('cook-class-member-idx');
          let cancelInfo = $('#pop-class2 #cancelInfo').val();

          if (!cancelInfo) {
              alert("취소 사유를 선택해 주세요.");
              return false;
          }

          let request: any = {
              COOK_CLASS_MEMBER_IDX: cookClassMemberIdx,
              CANCEL_INFO: cancelInfo
          }

          await this.flexcucClassModule.reqPatchFlexcucClassMember(request)
              .then(async (response) => {
                  console.logs(response);
                  if (response.code == '1') {
                      let template = document.getElementById('pop-class3');

                      await this.memberModule.reqGetCoupon(this.memberIdx)
                          .then((response) => {
                              let template = document.getElementById('pop-class3');

                              let value = {
                                  COUPON_COUNT: response.data.length
                              }

                              let html = Mustache.render(template.innerHTML, value);
                              $('#pop-class3').html(html);

                              UI
                                  .closeLayer('#pop-class2')
                                  .layerPopUp({selId: '#pop-class3'});
                          })
                  } else {
                      alert("이미 취소된 클래스 입니다.");
                      UI.closeLayer('#pop-class2');
                      console.error(response);
                  }
              });
      }

      public openFlexcucClassCouponRegistryModal(memberIdx: bigint) {
          let template = document.getElementById('pop-class1').innerHTML;
          let html = Mustache.render(template, {MEMBER_IDX: memberIdx});

          $('#pop-class1').html(html);

          UI.layerPopUp({selId: '#pop-class1'});
      }

      public async postMemberCoupon(memberIdx: bigint) {
          let couponCode = $('#coupon_code').val();

          if (!couponCode) {
              alert("수강권 번호를 입력해 주세요.");
              return false;
          }

          await this.memberModule.reqPostMemberCoupon(memberIdx, {COUPON_CODE: couponCode})
              .then((response) => {
                  if (response.code == '1') {
                      alert("정상적으로 등록되었습니다.");
                      UI.closeLayer('#pop-class1');
                  } else {
                      if (response.message) {
                          alert(response.message);
                      } else {
                          alert("이미 등록된 수강권 입니다.");
                      }
                  }
              });
      }

      public async applyFlexcucClass(cookClassIdx: bigint) {
          let $ui = $('#pop-apply .amount-wrap strong');

          let memberIdx           = $('#memberIdx').val();
          let memberType          = $('#memberType').val();
          let isVip: boolean      = $('.popup-wrap').data('is-vip');

          let personal: number    = $ui.data('count');
          let maxPersona: number  = $ui.data('max-count');

          if (!memberIdx) {
              alert("StyleCuc Club Member만 플렉스쿡 클래스 수강이 가능합니다.");
              return false;
          }

          if (isVip && memberType === 'Star') {
              alert("해당 플렉스쿡 클래스는 VIP member만 신청 가능합니다.");
              return false;
          }

          if (personal >= maxPersona) {
              alert("현재 사용 신청 가능한 수 보다 많은 참가자를 신청할수 없습니다.");
              return false;
          }

          if (confirm(`현재 ${personal} 명을 선택하셨습니다. 신청 하시겠습니까 ?`)) {

              let flexcucReq: any = {
                  PERSONAL: personal
              }

              if (!cookClassIdx || !memberIdx) {
                  alert("올바른 신청경로가 아닙니다. 새로고침하여 재접속 해주세요.");
                  return false;
              }

              await this.flexcucClassModule.reqPostFlexcucClass(cookClassIdx, memberIdx, flexcucReq)
                  .then((response) => {
                      if (response.code == '1') {
                          alert("신청이 완료 되었습니다. \n클래스 시작 3일전, 정원 미달 시 클래스가 취소될 수 있으며, 취소 시 신청 이력은 자동으로 복구됩니다.");
                          UI.closeLayer('#pop-apply');
                      } else {
                          if (response.message) {
                              alert(response.message);
                          } else {
                              alert("이미 신청하신 클래스 입니다.");
                          }
                      }
                  });
          }
      }

      public applyModalAddBtnEvent() {
          let $ui = $('#pop-apply .amount-wrap').find('strong');
          let count:number  = $ui.data('count');

          if (count > 2) {
              return false;
          }

          $ui.text((++count)+"명").data('count', count);
      }

      public applyModalMinBtnEvent() {
          let $ui = $('#pop-apply .amount-wrap').find('strong');
          let count = $ui.data('count');

          if (count < 2) {
              return false;
          }

          $ui.text((--count)+"명").data('count', count);
      }*/

      /* TODO :: 해당 모듈은 제외
      public async addMore() {
          let template = document.getElementById('flexcucClassListItemTemplate');

          let response =
              await this.flexcucClassModule.reqGetFlexcucClass(null, this.requestModel)
                  .then((response) => {
                      if (response.data) {
                          for (const key  in response.data.rows) {
                              const value = response.data.rows[key];

                              value['CLASS_COUNT'] = value['COOK_CLASSES'].length;
                              value['IS_PARKING'] = value['IS_PARKING'] ? '가능' : '붐가능';

                              let html = Mustache.render(template.innerHTML, value);

                              $('.center-list').append(html);
                          }

                          if (response.data.count <= ((this.requestModel.page+1) * this.requestModel.offset) + 1
                              || this.requestModel.offset > response.data.rows.length) {
                              $('.btn-list-more').hide();
                          }

                          $('.center-list .btn-center-detail').click(function(e){
                              e.preventDefault();
                              $(this).closest('li').toggleClass('open');
                          });
                      }

                      this.requestModel.page++;
                  });
      }*/

}
