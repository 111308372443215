import NewsModule from "../Modules/NewsModule";
import * as Mustache from 'mustache';
import {PagingResponse} from "../Modules/BaseResponse";

const $     = jQuery;
const UI    = globalThis.UI;

export interface PageRequestInterface {
    page:           number,
    offset:         number,
    category?:      string,
    searchText?:    string
}

export default class FlexcucIndex {

    public newsModule   = new NewsModule();

    public requestModel: PageRequestInterface = {
        page:       1,
        offset:     5,
        category:   '',
        searchText: '',
    };

    public constructor(props?: any) {
    }

    public async openNewsModal(newsIdx) {
        let template = document.getElementById('newsPopupTemplate');
        let response = await this.newsModule.reqGetNews(newsIdx);

        response.data.rows[0]['REG_DATE'] = response.data.rows[0]['REG_DATE'].substr(0, 10);

        let html = Mustache.render(template.innerHTML, response.data.rows[0]);
        document.getElementById('popup').innerHTML = html;

        $('.btn-close').on('click', function () {
            globalThis.scrollEnabled();
            $('#popup').fadeOut();
        });

        $('#popup').fadeIn();
        globalThis.scrollDisabled();
    }

    public async onSearchText(searchText?: string) {
        let request: PageRequestInterface = {
            page: 0,
            offset: 5,
            category: this.requestModel.category,
            searchText: $('#searchText').val().toString()
        };

        UI.loadingControl();
        await this.newsModule.reqGetNews(request)
            .then((response: PagingResponse) => {
                if (response.data.rows) {
                    let $listArea = $('.section-cont-area');
                    $listArea.empty();

                    this.requestModel = {
                        page: 1,
                        offset: 5,
                        category: this.requestModel.category,
                        searchText: $('#searchText').val().toString()
                    }

                    if (response.data.count <= (this.requestModel.page * this.requestModel.offset) +1
                        || this.requestModel.offset > response.data.rows.length) {
                        $('.btn-more-area').hide();
                    } else {
                        $('.btn-more-area').show();
                    }

                    if (response.data.rows.length > 0) {
                        let template = document.getElementById('newsListTemplate');

                        let value = {
                            NEWS_ITEMS: {
                                ROWS: response.data.rows.map((value) => {
                                    value['REG_DATE'] = value['REG_DATE'].substr(0, 10);
                                    value['IS_NEWS'] = value['CATEGORY'] == '뉴스';
                                    return value;
                                }),
                                IS_APPEND: response.data.count <= (this.requestModel.page * this.requestModel.offset)+1
                                    || this.requestModel.offset > response.data.rows.length
                            }
                        };

                        let html = Mustache.render(template.innerHTML, value);

                        $listArea.html(html);
                    } else {
                        let template = document.getElementById('newsListTemplate');
                        let html = Mustache.render(template.innerHTML, {NEWS_ITEMS: null});
                        $listArea.html(html);
                    }
                }

                UI.loadingControl('close');
            })
    }

    public async onChangeCategory(category?: string) {
        let request: PageRequestInterface = {
            page:   0,
            offset: 5,
            category: category,
            searchText: $('#searchText').val().toString()
        };

        UI.loadingControl();

        await this.newsModule.reqGetNews(request)
            .then((response: PagingResponse) => {
                if (response.data.rows) {
                    let $listArea = $('.section-cont-area');

                    $listArea.empty();

                    this.requestModel = {
                        page:   1,
                        offset: 5,
                        category: category,
                        searchText: $('#searchText').val().toString()
                    }

                    if (response.data.count <= (this.requestModel.page * this.requestModel.offset)+1
                        || this.requestModel.offset > response.data.rows.length) {
                        $('.btn-more-area').hide();
                    } else {
                        $('.btn-more-area').show();
                    }

                    if (response.data.rows.length > 0) {
                        let template = document.getElementById('newsListTemplate');

                        let value = {
                            NEWS_ITEMS: {
                                ROWS: response.data.rows.map((value) => {
                                    value['REG_DATE'] = value['REG_DATE'].substr(0, 10);
                                    return value;
                                }),
                                IS_APPEND: response.data.count <= (this.requestModel.page * this.requestModel.offset)+1
                                    || this.requestModel.offset > response.data.rows.length
                            }
                        };

                        let html = Mustache.render(template.innerHTML, value);

                        $listArea.html(html);
                    } else {
                        let template = document.getElementById('newsListTemplate');
                        let html = Mustache.render(template.innerHTML, {NEWS_ITEMS: null});
                        $listArea.html(html);
                    }
                }

                UI.loadingControl('close');
            })
    }

    public async addMore() {
        let template = document.getElementById('newsListItemTemplate');

        UI.loadingControl();

        let response =
            await this.newsModule.reqGetNews(this.requestModel)
                .then((response: PagingResponse) => {
                    if (response.data) {
                        for (const key  in response.data.rows) {
                            let value = response.data.rows[key];

                            value['REG_DATE'] = value['REG_DATE'].substr(0, 10);
                            let html = Mustache.render(template.innerHTML, value);

                            $('.news-list').append(html);
                        }

                        if (response.data.count <= (this.requestModel.page * this.requestModel.offset)+1
                            || this.requestModel.offset > response.data.rows.length) {
                            $('.btn-more-area').hide();
                        }
                    }

                    UI.loadingControl('close');
                    this.requestModel.page++;
                });
    }

}